// Copyright 2022 Gan Tu
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { useSelector } from "react-redux";

function useLoggedInUser() {
  return useSelector((store) => store.SessionReducer.user);
}

function useLoggedInUserId() {
  return useSelector((store) => store.SessionReducer.user?.uid);
}

function useEmailForSignIn() {
  return useSelector((store) => store.SessionReducer.emailForSignIn);
}

function useEmailVerificationSuccess() {
  return useSelector((store) => store.SessionReducer.emailVerificationSuccess);
}

function useEmailVerificationFailed() {
  return useSelector((store) => store.SessionReducer.emailVerificationFailed);
}

export {
  useLoggedInUser,
  useLoggedInUserId,
  useEmailForSignIn,
  useEmailVerificationSuccess,
  useEmailVerificationFailed,
};
